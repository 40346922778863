<template>
  <v-layout>
    <v-app-bar color="white" dark fixed app>
      <Logo />

<!--      <v-toolbar-items>-->
<!--        <v-btn exact v-for="item in menu" :key="item.icon" :to="item.link" text color="blue darken-1">{{-->
<!--            item.title-->
<!--          }}-->
<!--        </v-btn>-->
<!--      </v-toolbar-items>-->

      <v-spacer></v-spacer>

      <User />
    </v-app-bar>

    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-layout>
</template>

<script>
import Logo from '@/components/AppBar/Logo'
import User from '@/components/AppBar/User'

export default {
  components: {
    Logo,
    User
  },

  beforeMount() {
    if (this.$store.getters.user.role !== 'worker') {
      this.$router.push({path: `/${this.$store.getters.user.role}`})
    }
  }
}
</script>
